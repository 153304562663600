import React, { ReactNode } from "react";
import { AiFillPhone } from 'react-icons/ai';
import { BsClockHistory } from 'react-icons/bs';
import { IoLocationOutline } from 'react-icons/io5';
import { HeaderInfo } from "../HeaderInfo/Header";
import { Partners } from "../Partners/Partners";
import Styles from "./layout.module.scss"

const Layout = (
	{
		children
	}: {
		children: ReactNode
	}) =>
{
	return (
		<>
			<div className={Styles.headerWrapper}>
				<div className={Styles.header}>
					<a href={"/"}>
						<div className={Styles.logo}/>
					</a>
					<div className={Styles.headerMenu}>
						<HeaderInfo title={"Ми тут"} info={"м.Львів вул Шараневича"}
						            icon={<IoLocationOutline size={40} color={"orange"}/>}/>
						<HeaderInfo title={"Електронна   пошта"} info={'office@sp-service.com'}
						            icon={<BsClockHistory size={40} color={"orange"}/>}/>
						<HeaderInfo title={"Телефонуйте"} info={"+38 032 245 1120"}
						            icon={<AiFillPhone size={40} color={"orange"}/>}/>
					</div>
				</div>
			</div>

			<div className={Styles.container}>
				{children}
			</div>
			<div className={Styles.footerWrapper}>
				<div className={Styles.footer}>
					<div className={Styles.text}>Ми завжди раді оперативно надати Вам професійну комп'ютерну допомогу,
						телефонуйте, консультація безкоштовна!
					</div>
					<a href={"/"}>
						<div className={Styles.logo}/>
					</a>
					<div className={Styles.company}>«SP-Service» © Всі права захищені.
					</div>
				</div>
			</div>
		</>
	)
}


export default Layout