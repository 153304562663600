import React from "react";
import Styles from "./HeaderInfo.module.scss";

type HeaderInfoType = {
	title: string
	info: string
	icon: any
}

export function HeaderInfo(props: HeaderInfoType)
{
	return (
		<div className={Styles.headerInfo}>
			<div className={Styles.icon}>
				{props.icon}
			</div>
			<div>
				<div className={Styles.title}>{props.title}</div>
				<p>{props.info}</p>
			</div>
		</div>
	)
}
