import React from 'react';
import './App.scss';
import MainRouter from "./router";

function RootContent()
{
	return (
		<>
			<MainRouter/>
		</>
	)
}

export default RootContent;
